import Cookies from 'js-cookie';
import {COUNSEL_SERVICE_ID} from '@common/consts/counselServiceConsts';

window.serviceId = (() => {
	const pathname = document.location.pathname;

	if (/\/iamteacherpc/.test(pathname)) {
		return COUNSEL_SERVICE_ID.IAMTEACHERPC;
	}
	if (/\/iamteacherapp/.test(pathname)) {
		return COUNSEL_SERVICE_ID.IAMTEACHERAPP;
	}
	if (/\/iamschoolpc/.test(pathname)) {
		return COUNSEL_SERVICE_ID.IAMSCHOOLPC;
	}
	if (/\/iamschoolapp/.test(pathname)) {
		const serviceId = Cookies.get('serviceId');
		if (serviceId === 'iamstudent') {
			return COUNSEL_SERVICE_ID.IAMSTUDENTAPP;
		}
		return COUNSEL_SERVICE_ID.IAMSCHOOLAPP; //쿠키가 없는경우 iamschoolapp
	}
})();

export function isStudentApp() {
	return window.serviceId === COUNSEL_SERVICE_ID.IAMSTUDENTAPP;
}

